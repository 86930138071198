const resource = {
    en: {
        heading: 'Account Properties',
        title: 'Title',
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'Email Address',
        update: 'Update',
        success: 'Account Properties successfully updated',
        want_email_notifications: 'Email Notifications'
    },
    de: {
        heading: 'Profileigenschaften',
        title: 'Titel',
        first_name: 'Vorname',
        last_name: 'Nachname',
        email: 'Email Adresse',
        update: 'Änderungen übernehmen',
        success: 'Änderungn erfolgreich übernommen',
        want_email_notifications: 'Benachrichtigungen per E-Mail'
    }
}

export default resource;