<template>
    <div class="dashboard-main">
        <h1 class="dashboard-main-heading" v-html="resource[lang].heading" />
        <form @submit.prevent="update">
            <div class="grid">
                <InputText :label="resource[lang].title" v-model="title" />
                <div></div>
            </div>
            <div class="grid">
                <InputText :label="resource[lang].first_name" v-model="first_name" required />
                <InputText :label="resource[lang].last_name" v-model="last_name" required />
            </div>
            <InputText :label="resource[lang].email" v-model="email" required />

            <q-toggle
                v-model="want_email_notifications"
                color="primary"
                keep-color
                :label="resource[lang].want_email_notifications"
            />

            <div class="actions">
                <Button color="primary" :label="resource[lang].update" submit />
            </div>
        </form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { useQuasar } from 'quasar'

import resource from '@/assets/resources/account-properties-resource'

import Button from '@/components/basic/Button'
import InputText from '@/components/basic/InputText'

import { promisePutRequest } from '@/assets/js/axios-utilities'

export default {
    components: {
        Button,
        InputText
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            // form data
            title: '',
            first_name: '',
            last_name: '',
            email: '',
            want_email_notifications: '',

            resource
        }
    },
    computed: {
        ...mapGetters(['user']),
    },
    setup() {
        const quasar = useQuasar();
        
        return { 
            quasar
        }
    },
    created() {
        this.init();
    },
    methods: {
        // Init
        init() {
            if(this.user) {
                this.title = this.user.title
                this.first_name = this.user.first_name
                this.last_name = this.user.last_name
                this.email = this.user.email
                this.want_email_notifications = this.user.want_email_notifications
            }
        },

        // Requests
        updateAccountPropertiesRequest() {
            const request_data = {
                title: this.title,
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                want_email_notifications: this.want_email_notifications,
            }
            return promisePutRequest('/update-me', request_data);
        },

        // Actions
        async update() {
            this.$store.dispatch('loading', true)
            const response = await this.updateAccountPropertiesRequest();
            this.$store.dispatch('loading', false)

            this.$store.dispatch('user', response.data.user);

            this.quasar.notify({
                position: 'bottom-left', 
                message: this.resource[this.lang].success, 
                color: 'positive'
            });
        }
    },
    watch: {
        user: function() {
            this.init();
        }
    }
}
</script>

<style lang="scss" scoped>

form > * + * {
    margin-block-start: 1rem;
}

.actions {
    margin-block-start: 2rem;
}

.grid {
    gap: 1rem;
}

@media (max-width: 40rem) {

    .grid {
        display: block;
    }

    .grid > * + * {
        margin-block-start: 1rem;
    }
}

</style>